import React from 'react';
import './BlueCast.css'
import XONE from '../images/Bluecast/XONE.jpg'
import XFILIGREE from '../images/Bluecast/XFILIGREE.jpg'

import x5Dragon from '../images/Bluecast/x5Dragon.png'

import whatsapp from '../images/Whatsapp.svg'
import Footer from '../Componentes-Es/FooterEs';



const BlueCast = () => {
    return (
        <>
        <div className='BLueWrap'>
            <div className='BluecastContent'>
                <div className="BcTitle">
                    <h4>Somos Distribuidores Oficiales</h4>
                    <h1>
                        BlueCast
                    </h1>
                </div>

                <img src={XONE} alt="X-One" />



            </div>
            <div className="BcText">
                <p> <span>Bluecast</span> es la marca italiana de resinas #1 para casting de <span>joyería  </span>
                    en el mundo, Con Aplicaciones también en otras industrias como la mecánica dental.
                    Entre nuestros productos contamos las referencias más actuales como la <span>BEST SELLER,</span> BlueCast <span>X-One, </span>
                    X-One v2, X-filigree, con excelente detalles, aptas para engaste, lettering,
                    y la <span>única fórmula</span> en el mundo que permite una quema rápida de hasta 1 Hora,
                    lo que las hace resinas altamente sostenibles y excelentes para el ahorro de energía.
                    Ponte en contacto para saber más</p>
                <div className="imageDWrap">
                    <img className='x5Dragon' src={x5Dragon} alt="Ilustracion de dragon en x5" />
                    <h4>Ventas</h4>
                    <p>Escríbenos a nuestro chat directo para ventas.</p>
                    <div className="BCContactCard">
                        <a
                            href="https://wa.me/573052259330?text=Hola%20estoy%20interezad@%20en%20sus%203D%20servicios"
                            className="Whatsappli"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={whatsapp}
                                alt="Whatsapp icon"
                                className="contactIcon"
                            />
                            <h4 >whatsapp</h4>
                        </a>
                    </div>

                </div>

                <div>
                <h4>Soporte</h4>
                    <p>Si tiene dudas o necesita recibir soporte sobre los productos de Bluecast, por favor ingrese al siguiente enlace o póngase en contacto con nosotros por medio de WhatsApp.</p>
                    <div className="BCContactCard">
                        <a
                            href="https://www.bluecast.info/support"
                            className="Whatsappli"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <h4 >Soporte Bluecast</h4>
                        </a>
                    </div>
                   
                </div>

                <div>
                <h4>Perfiles de impresión</h4>
                    <p>En el siguiente enlace encontrará perfiles diseñados para un gran número de marcas de impresoras, Con cada una de las referencias de los productos BlueCast.</p>
                    <div className="BCContactCard">
                        <a
                            href="https://www.bluecast.info/download#how-to-use-printing-profile"
                            className="Whatsappli"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <h4>Perfiles de impresión</h4>
                        </a>
                    </div>
                   
                </div>


            </div>

        </div>
       <Footer/>
       </>
    );
    
}

export default BlueCast;